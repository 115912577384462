<template>
  <div class="address-selector">
    <h4>
      Privat eller företag?
    </h4>

    <div class="address-selector-radio-button">
      <RadioInput
        id="private"
        v-model="isCorporate"
        label="Visa privattjänster"
        :radio-value="false" />
    </div>

    <div class="address-selector-radio-button">
      <RadioInput
        id="corporate"
        v-model="isCorporate"
        label="Visa företagstjänster"
        :radio-value="true" />
    </div>

    <h4>Välj din anslutning</h4>
    <p>Flera anslutningar finns på adressen. Välj din anslutning nedan.</p>

    <table
      class="address-selector-table"
      aria-label="Address Selection">
      <tr>
        <th
          class="title-200"
          scope="col">
          Nr
        </th>
        <th
          class="title-200"
          scope="col">
          Våning
        </th>
        <th
          class="title-200"
          scope="col">
          Entrie
        </th>
        <th
          class="title-200"
          scope="col">
          Lägenhetsnumer
        </th>
        <th
          class="title-200"
          scope="col" />
      </tr>
      <tr
        v-for="(item, index) in addresses"
        :key="index"
        class="address-selector-table__address"
        @click="handleAddressSelection(item)">
        <td class="address-selector-table__street-number">
          {{ item.streetNumber }}
        </td>
        <td class="address-selector-table__floor">
          {{ item.floor }}
        </td>
        <td class="address-selector-table__entrance">
          {{ item.entrance }}
        </td>
        <td class="address-selector-table__apartmentNumber">
          {{ item.apartmentNumber }}
        </td>
        <td>
          <img
            class="address-selector-table__icon"
            alt="arrow"
            src="@/assets/svg/arrow_right.svg"
            width="15"
            height="15">
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import {
  mapActions, mapState
} from 'vuex'
import RadioInput from '@/components/form-components/RadioInput'
import { RouteName } from '@/router/router-constants'

export default {
  components: { RadioInput },
  data () {
    return {
      isCorporate: false
    }
  },
  computed: {
    ...mapState('AddressSearch', [
      'addresses',
      'accessType',
      'referenceNumber'
    ])
  },
  methods: {
    ...mapActions('AddressSearch', [
      'setSelectedAddress',
      'precheckOnAddress'
    ]),
    ...mapActions('AvailableOffers', ['searchOffersPublic']),
    async handleAddressSelection (selectedAddress) {
      const punktId = selectedAddress.pointId

      this.setSelectedAddress(selectedAddress)

      await this.precheckOnAddress({
        'city': selectedAddress.city,
        'street': selectedAddress.streetName,
        'streetNumber': selectedAddress.streetNumber,
        'apartmentNumber': selectedAddress.apartmentNumber,
        'entrance': selectedAddress?.entrance ?? '',
        'floor': selectedAddress?.floor ?? '',
        'stairwell': selectedAddress?.stairwell ?? ''
      })

      if (this.accessType === '250' && this.referenceNumber) {
        const redirectURL = `${process.env.VUE_APP_Z_MARKET_URL}/${this.referenceNumber}`
        window.location.href = redirectURL
      } else {
        this.searchOffersPublic({
          punktId: punktId,
          isCorporate: this.isCorporate
        })

        this.$router.push({ name: RouteName.public.AvailableOffers })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.address-selector {
  h4 {
    margin: 26px 0 16px;
  }

  &-radio-button {
    margin-bottom: 20px;
  }

  &-table {
    width: 100%;
    border-collapse: collapse;

    th {
      text-align: left;
    }

    tr {
      border-top: 1px solid $grey-200;

      &:hover:not(:first-of-type) {
        cursor: pointer;
        background-color: $grey-100;
      }

      &:active:not(:first-of-type) {
        background-color: $grey-200;
      }
    }

    tr:first-of-type {
      border-top: none;
    }

    td {
      font-size: 13px;
      letter-spacing: 0.5px;
    }

    th, td {
      padding: 10px 3px;
    }
  }
}
</style>
