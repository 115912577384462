const STREET_NAME_REGEXP = /^[A-Za-z*\s0-9\055\072\305\304\326\344\345\366\311\351\374\334]{0,50}$/
const CITY_REGEXP = /^[A-Za-z*\s\055\072\305\304\326\344\345\366\311\351\374\334]{0,50}$/

export const street = value => {
  return STREET_NAME_REGEXP.test(value)
}

export const city = value => {
  return CITY_REGEXP.test(value)
}
