<template>
  <div
    class="address-search"
    :class="{'address-search--alternative': alternative }">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="address-search-title">
            {{ alternative ? 'Ange adress' : 'Se vilka tjänster du kan beställa' }}
          </h2>
          <AddressSearchForm ref="addressSearchFormRef" />
        </div>
      </div>

      <Modal
        v-if="addressesFound && !loadingOffers"
        ref="addressListRef"
        class="address-search-modal"
        heading="Ett ögonblick..."
        position="center"
        variant="input"
        @close="handleCloseAddressSelector">
        <template>
          <AddressSearchSelector />
        </template>
      </Modal>

      <Modal
        v-if="noAddressesFound"
        id="no-addresses-modal"
        class="address-search-modal"
        heading="Adress hittas inte"
        position="center"
        variant="warning"
        @close="handleCloseInfoModal">
        <template>
          <p>Addressen kunde inte hittas. Kontrollera uppgifterna och försök igen.</p>
        </template>
      </Modal>

      <Modal
        v-if="noFiberFound"
        id="no-fiber-modal"
        class="address-search-modal"
        heading="Fiberanslutning saknas"
        position="center"
        variant="info"
        @close="handleCloseInfoModal">
        <template>
          <p>Din adress är inte ansluten till Öppen Fiber</p>
          <h3>Vill du ansluta dig till Öppen Fiber?</h3>
          <ul>
            <li><p>Läs mer om hur du ansluter dig till fibernätet på <a href="https://anslutning.telia.se/fastighetsagare">anslutning.telia.se/fastighetsagare.</a></p></li>
            <li><p>Bor du i villa hittar du info på <a href="https://anslutning.telia.se">anslutning.telia.se</a></p></li>
          </ul>
        </template>
      </Modal>

      <Modal
        v-if="systemError"
        id="system-error-modal"
        class="address-search-modal"
        heading="Systemfel uppstod"
        position="center"
        variant="error"
        @close="handleCloseInfoModal">
        <template>
          <p>Kontakta Kundservice.</p>
        </template>
      </Modal>

      <div
        v-show="loadingOffers"
        ref="loadingOffersRef"
        class="address-search-loader-stub">
        <p>Vi hämtar nu erbjudandet från operatörens beställningssida, det kan ta några sekunder.</p>
        <LoadingSpinner />
      </div>

      <Modal
        v-show="error"
        class="address-search-modal"
        heading="Ojdå"
        position="center"
        variant="warning"
        @close="handleCloseError">
        <template>
          <p>{{ error }}</p>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import AddressSearchForm from '@/components/address-search/AddressSearchForm'
import AddressSearchSelector from '@/components/address-search/AddressSearchSelector'
import Modal from '@/components/modal/Modal'
import LoadingSpinner from '@/components/LoadingSpinner'
import { scrollTo } from '@/utils/window.utils'

import {
  mapActions, mapState, mapGetters
} from 'vuex'

export default {
  components: {
    AddressSearchSelector,
    AddressSearchForm,
    Modal,
    LoadingSpinner
  },
  props: {
    alternative: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('AddressSearch', [
      'error',
      'addresses'
    ]),
    ...mapState('AvailableOffers', ['loadingOffers']),
    ...mapGetters('AddressSearch', [
      'noAddressesFound',
      'noFiberFound',
      'addressesFound',
      'systemError'
    ])
  },
  watch: {
    addressesFound (found) {
      if (found) {
        this.$nextTick(() => {
          this.scroll(this.$refs['addressListRef'].$el)
        })
      }
    },
    loadingOffers (isLoading) {
      if (isLoading) {
        this.$nextTick(() => {
          this.scroll()
        })
      }
    }
  },
  destroyed () {
    this.clearResponseCode()
  },
  methods: {
    ...mapActions('AddressSearch', [
      'clearError',
      'clearAddresses',
      'clearResponseCode'
    ]),
    handleCloseError () {
      this.clearError()
      this.scroll()
    },
    handleCloseAddressSelector () {
      this.clearAddresses()
      this.scroll()
    },
    handleCloseInfoModal (refName) {
      this.clearResponseCode()
      this.scroll(refName)
    },
    scroll (element = this.$refs['addressSearchFormRef'].$el.offsetParent) {
      scrollTo(element.offsetTop)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.address-search {
  background-color: $grey-100;
  padding: 60px 0;

  &--alternative {
    background-color: $grey-100;
  }

  &-title {
    text-align: center;
  }

  &-modal {
    margin-top: 50px;
  }

  &-loader-stub {
    margin: 50px auto auto;
    width: 50%;
    background-color: $core-white;
    padding: 50px;
  }
}
</style>
