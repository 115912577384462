<template>
  <div
    class="container address-search-form"
    @keyup.enter="handleSearchAddresses">
    <div class="row justify-content-md-center">
      <div class="col-xl-4 col-lg-4">
        <AutocompleteInput
          id="ct"
          v-model="cityProxy"
          label="Stad"
          :options="cities"
          :validator="$v.city"
          :validation-messages="validationMessages.city"
          @input="handleSearchStringChange($event, INPUT.CITY)"
          @select="handleSelect($event, INPUT.CITY)" />
      </div>
      <div class="col-xl-4 col-lg-4">
        <AutocompleteInput
          id="strt"
          v-model="streetProxy"
          label="Gata"
          :options="streets"
          :validator="$v.street"
          :validation-messages="validationMessages.street"
          @input="handleSearchStringChange($event, INPUT.STREET)"
          @select="handleSelect($event, INPUT.STREET)" />
      </div>
      <div class="col-xl-2 col-lg-4">
        <AutocompleteInput
          id="strtNmbr"
          v-model="streetNumberProxy"
          label="Gatunummer"
          :options="streetNumbers"
          :validator="$v.streetNumber"
          :validation-messages="validationMessages.streetNumber"
          @input="handleSearchStringChange($event, INPUT.STREET_NUMBER)"
          @select="handleSelect($event, INPUT.STREET_NUMBER)" />
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-md-3 address-search-form-button-container">
        <MainButton
          label="Testa"
          class="address-search-form-button"
          :expanded="true"
          :loading="loadingAddresses"
          @click="handleSearchAddresses" />
      </div>
    </div>
  </div>
</template>

<script>
import AutocompleteInput from '@/components/form-components/AutocompleteInput'
import MainButton from '@/components/MainButton'

import debounce from 'lodash/debounce'

import {
  mapActions, mapState
} from 'vuex'

import {
  alphaNum, required
} from 'vuelidate/lib/validators'
import {
  city, street
} from '@/utils/validation-rules'

const INPUT = {
  CITY: 'city',
  STREET: 'street',
  STREET_NUMBER: 'streetNumber'
}

export default {
  components: {
    MainButton,
    AutocompleteInput
  },
  data () {
    return {
      INPUT,
      validationMessages: {
        [INPUT.CITY]: {
          required: 'Stad krävs',
          city: 'Felaktigt tecken i stad'
        },
        [INPUT.STREET]: {
          required: 'Gata krävs',
          street: 'Felaktigt tecken i gatunamn'
        },
        [INPUT.STREET_NUMBER]: {
          required: 'Gatunummer krävs',
          alphaNum: 'Enbart siffror'
        }
      }
    }
  },
  validations: {
    [INPUT.CITY]: {
      required,
      city
    },
    [INPUT.STREET]: {
      required,
      street
    },
    [INPUT.STREET_NUMBER]: {
      required,
      alphaNum
    }
  },
  computed: {
    ...mapState('AddressSearch', [
      'loadingAutocompleteInput',
      'loadingAddresses',
      'cities',
      'city',
      'streets',
      'street',
      'streetNumbers',
      'streetNumber'
    ]),
    cityProxy: {
      get () {
        return this.city
      },
      set (value) {
        this.setSelectedCity(value)
        this.$v.street.$reset()
        this.$v.streetNumber.$reset()
      }
    },
    streetProxy: {
      get () {
        return this.street
      },
      set (value) {
        this.setSelectedStreet(value)
        this.$v.streetNumber.$reset()
      }
    },
    streetNumberProxy: {
      get () {
        return this.streetNumber
      },
      set (value) {
        this.setSelectedStreetNumber(value)
      }
    },
    searchDebounced () {
      return debounce(function (input, searchAction) {
        searchAction(input)
      }, 300)
    }
  },
  methods: {
    handleSearchStringChange (value, inputField) {
      this.clearResponseCode()

      let searchFunction
      let minInputLength = 2

      switch (inputField) {
        case INPUT.CITY:
          searchFunction = this.searchCities
          break
        case INPUT.STREET:
          searchFunction = this.searchStreets
          break
        case INPUT.STREET_NUMBER:
          minInputLength = 0
          searchFunction = this.searchStreetNumbers
      }

      if (value.length >= minInputLength) {
        this.searchDebounced(value, searchFunction)
      } else {
        this.cancelAutocompleteInput()
        this.searchDebounced.cancel()
      }
    },
    handleSelect (value, inputField) {
      if (this[inputField] === value.value) {
        return
      }

      let selectAction

      switch (inputField) {
        case INPUT.CITY:
          selectAction = this.setSelectedCity
          break
        case INPUT.STREET:
          selectAction = this.setSelectedStreet
          break
        case INPUT.STREET_NUMBER:
          selectAction = this.setSelectedStreetNumber
          break
      }
      selectAction(value.value)

    },
    handleSearchAddresses () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.searchAddresses({
        'city': this.city,
        'street': this.street,
        'streetNumber': this.streetNumber
      })
    },
    ...mapActions('AddressSearch', [
      'searchCities',
      'searchStreets',
      'searchStreetNumbers',
      'cancelAutocompleteInput',
      'searchAddresses',
      'clearCities',
      'clearStreets',
      'clearStreetNumbers',
      'setSelectedCity',
      'setSelectedStreet',
      'setSelectedStreetNumber',
      'clearResponseCode'
    ])
  }
}
</script>

<style lang="scss" scoped>
.address-search-form {
  text-align: left;

  .address-search-form-button-container {
    margin-top: 45px;
    text-align: center;
  }
}
</style>
